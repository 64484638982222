// src/components/ManageEvents.js
import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  AccordionActions,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
  Tooltip,
} from "@mui/material";
import ViewEditEvent from "./ViewEditEvent";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AppContext } from "../App";
import { db } from "../config/firebaseConfig";

const ManageEvents = () => {
  const [viewEditEventOpen, setViewEditEventOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const {
    events,
    setEvents,
    defaultTheme,
    userData,
    setMessage,
    setError,
    setInfo,
    setSuccess,
  } = useContext(AppContext);

  const handleViewEditEventClose = () => {
    setViewEditEventOpen(!viewEditEventOpen);
    setSelectedEvent(null);
  };

  const cancelEvent = async (eventId) => {
    try {
      let eventsCopy = events.slice();
      let idx = eventsCopy.findIndex((e) => e.id === eventId);
      let canceledEvent = eventsCopy.find((e) => e.id === eventId);
      await db
        .collection("events")
        .doc(eventId)
        .update({
          canceled: true,
        })
        .then(() => {
          canceledEvent.canceled = true;
          eventsCopy[idx] = canceledEvent;
          setInfo(true);
          setMessage("Event canceled successfully");
          setEvents(eventsCopy);
          setCancelModal(false);
          setSelectedEvent(null);
        });
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };

  return (
    <Grid item container xs={12} sx={{ marginBottom: "2rem" }}>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Manage Events {events?.length > 0 ? `(${events.length})` : "(0)"}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            variant="contained"
            sx={{ marginBottom: "1rem", ml: 2 }}
            onClick={() => setViewEditEventOpen(true)}
          >
            Create New Event
          </Button>
          <Typography sx={{ marginBottom: "1rem" }}>Upcoming Events</Typography>
          <Grid container spacing={2} style={{ padding: 10 }}>
            {/* Map over events here */}
            {events.length > 0 &&
              events.map((event, idx) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    style={{
                      padding: 10,
                      position: "relative",
                    }}
                  >
                    {event.canceled && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: "white",
                          background: "red",
                          padding: "5px",
                          fontWeight: "bold",
                          borderRadius: "4px",
                          position: "absolute",
                          top: 5,
                          left: -1,
                          zIndex: 1,
                        }}
                      >
                        Canceled
                      </Typography>
                    )}

                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        height: 120,
                        opacity: event.canceled ? 0.55 : 1,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={3}
                        style={{
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={event.image}
                          alt={""}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Grid>
                      <Grid item xs={9} style={{ padding: 5 }}>
                        <Box sx={{}}>
                          <Typography style={{ fontWeight: "bold" }}>
                            {event.title.slice(0, 19) + "..."}
                          </Typography>
                          <Typography variant="caption">
                            {event.startDate
                              ? new Date(
                                  event.startDate?.seconds * 1000
                                ).toLocaleDateString()
                              : ""}
                            @{" "}
                            {event.startDate
                              ? new Date(
                                  event.startDate?.seconds * 1000
                                ).toLocaleTimeString()
                              : ""}
                          </Typography>
                          {!event.canceled && (
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"flex-end"}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setCancelModal(true);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setViewEditEventOpen(true);
                                }}
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: 5 }}
                              >
                                Edit
                              </Button>
                            </Grid>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Modal
        open={cancelModal}
        onClose={() => {
          setSelectedEvent(null);
          setCancelModal(false);
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={7}
          lg={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            borderRadius: 12,
            padding: 20,
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            style={{
              //   fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Are you sure you want to cancel this event?
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            {selectedEvent?.title}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              cancelEvent(selectedEvent.id);
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedEvent(null);
              setCancelModal(false);
            }}
          >
            No
          </Button>
        </Grid>
      </Modal>

      <ViewEditEvent
        open={viewEditEventOpen}
        setClose={handleViewEditEventClose}
        event={selectedEvent}
        events={events}
        setEvents={setEvents}
      />
    </Grid>
  );
};

export default ManageEvents;
