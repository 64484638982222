// src/components/Ticket.js
import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { AppContext } from "../App";

const Ticket = ({ event, ticketCounts, setTicketCounts, tixRef }) => {
  const { getTicketFee } = useContext(AppContext);
  console.log(event.tickets);
  const handleIncrement = (index) => {
    if (
      event.tickets[index].quantity > ticketCounts[index] &&
      event.tickets[index].limit > ticketCounts[index]
    ) {
      setTicketCounts(
        ticketCounts.map((count, i) => (i === index ? count + 1 : count))
      );
    }
  };

  const handleDecrement = (index) => {
    setTicketCounts(
      ticketCounts.map((count, i) =>
        i === index && count > 0 ? count - 1 : count
      )
    );
  };

  useEffect(() => {
    console.log(ticketCounts);
  }, [ticketCounts]);

  return (
    <Grid item container sx={{ marginBottom: "2rem", padding: "10px" }}>
      <Typography ref={tixRef} variant="h5" sx={{ marginBottom: "1rem" }}>
        Tickets
      </Typography>

      {event.tickets.length > 0 ? (
        event.tickets.map((ticket, idx) => {
          const ticketFee = getTicketFee(ticket.price);
          console.log("TICKET FEE =>", ticketFee);
          const hasSpecifiedDates = ticket?.specifyDates;
          let isAvailable = true;
          if (!!hasSpecifiedDates) {
            isAvailable =
              new Date(ticket?.availableStarting?.seconds * 1000).getTime() <=
                new Date().getTime() &&
              new Date(ticket?.availableUntil?.seconds * 1000).getTime() >=
                new Date().getTime();
          }
          return (
            <Grid
              item
              container
              xs={12}
              key={idx}
              sx={{
                marginBottom: "1rem",
                border:
                  ticketCounts[idx] === 0
                    ? "1px solid lightgrey"
                    : "2px solid blue",
                borderRadius: "12px",
                padding: 2,
                alignItems: "center",
                background: "white",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" style={{ marginBottom: 20 }}>
                  {ticket.name}
                </Typography>
                {ticket.remaining !== 0 ? (
                  <Typography variant="body1" sx={{ marginTop: "auto" }}>
                    ${ticket.price.toFixed(2)}{" "}
                    {ticket.price !== 0 && (
                      <Typography variant="caption" style={{ color: "grey" }}>
                        + {ticketFee} Fee
                      </Typography>
                    )}
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: "auto",
                      background: "red",
                      color: "white",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "fit-content",
                      fontWeight: "bold",
                    }}
                  >
                    SOLD OUT
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {!isAvailable ? (
                    <Typography variant="body1" style={{ color: "grey" }}>
                      Available{" "}
                      {new Date(
                        ticket?.availableStarting?.seconds * 1000
                      ).toLocaleDateString()}
                    </Typography>
                  ) : (
                    <>
                      {ticket.remaining !== 0 ? (
                        <>
                          <IconButton
                            style={{
                              background: ticketCounts[idx] !== 0 ? "blue" : "",
                              color: ticketCounts[idx] !== 0 ? "white" : "",
                            }}
                            disabled={ticketCounts[idx] === 0}
                            onClick={() => handleDecrement(idx)}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <Typography variant="body1" sx={{ margin: "0 1rem" }}>
                            {ticketCounts[idx]}
                          </Typography>
                          <IconButton
                            style={{
                              background:
                                event.tickets[idx].quantity !==
                                  ticketCounts[idx] &&
                                event.tickets[idx].limit !==
                                  ticketCounts[idx] &&
                                ticketCounts[idx] !== 0
                                  ? "blue"
                                  : "",
                              color:
                                event.tickets[idx].quantity !==
                                  ticketCounts[idx] &&
                                event.tickets[idx].limit !==
                                  ticketCounts[idx] &&
                                ticketCounts[idx] !== 0
                                  ? "white"
                                  : "",
                            }}
                            disabled={
                              event.tickets[idx].quantity ===
                                ticketCounts[idx] ||
                              event.tickets[idx].limit === ticketCounts[idx]
                            }
                            onClick={() => handleIncrement(idx)}
                          >
                            <AddIcon />
                          </IconButton>
                        </>
                      ) : null}
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button variant="text" sx={{ marginLeft: "auto" }}>
                    Read More
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <Typography variant="body1">
          No Tickets Available at this time.
        </Typography>
      )}
    </Grid>
  );
};

export default Ticket;
