// src/screens/AuthScreen.js
import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { motion } from "framer-motion";
import Login from "../components/Login";
import SignUp from "../components/SignUp";

const AuthScreen = ({ isNotAuthPage }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",

        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        width: "100%",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        {activeTab === 0 && <Login isNotAuthPage={isNotAuthPage} />}
        {activeTab === 1 && <SignUp />}
      </motion.div>
    </Box>
  );
};

export default AuthScreen;
