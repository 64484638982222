export const musicGenres = [
  "Alternative",
  "Blues",
  "Classical",
  "Country",
  "Dance",
  "Electronic",
  "Folk",
  "Funk",
  "Hip-Hop",
  "Heavy Metal",
  "Indie",
  "Jazz",
  "Latin",
  "Metal",
  "Pop",
  "Punk",
  "R&B",
  "Regge",
  "Rock",
  "Soul",
  "Soundtrack",
  "Spoken Word",
  "World",
  "Other",
];

export const testComments = [
  {
    name: "John Doe",
    post: "This event looks amazing! Can't wait to attend.",
    replies: [],
    thumbsUp: 10,
    thumbsDown: 2,
    uid: "user1",
    createdAt: "2024-07-16T10:30:00",
  },
  {
    name: "Jane Smith",
    post: "I've been to similar events and they were great. Looking forward to this one!",
    replies: [],
    thumbsUp: 8,
    thumbsDown: 1,
    uid: "user2",
    createdAt: "2024-07-16T11:00:00",
  },
  {
    name: "Alex Johnson",
    post: "Does anyone know if there are any special guests attending?",
    replies: [],
    thumbsUp: 5,
    thumbsDown: 0,
    uid: "user3",
    createdAt: "2024-07-16T11:30:00",
  },
  {
    name: "Emily Brown",
    post: "Can't wait to see everyone there! It's going to be a blast!",
    replies: [],
    thumbsUp: 7,
    thumbsDown: 1,
    uid: "user4",
    createdAt: "2024-07-16T12:00:00",
  },
];

export const getTicketFee = (price) => {
  console.log("THIS IS THE PRICE", price);
  if (price === 0) {
    return 0.0;
  } else if (price > 0 && price < 31) {
    return 2.99;
  } else if (price >= 31 && price < 85) {
    return 4.99;
  } else if (price >= 85) {
    return price * 0.06;
  } else {
    return undefined; // or any other default value you prefer
  }
};
