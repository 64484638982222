// src/screens/ShowsScreen.js
import React from "react";
import { Box, Grid } from "@mui/material";
import Events from "../components/Events";

const ShowsScreen = () => {
  return (
    <Grid
      item
      container
      xs={12}
      //   md={10}
      //   lg={8}
      sx={{ padding: "1rem", background: "white", justifyContent: "center" }}
    >
      <Grid
        item
        container
        xs={12}
        md={10}
        lg={8}
        sx={{ padding: "1rem", background: "white" }}
      >
        <Events />
      </Grid>
    </Grid>
  );
};

export default ShowsScreen;
