// src/components/Checkout.js
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import { AppContext } from "../App";
import {
  ArrowBackIos,
  ArrowLeft,
  Close,
  Info,
  InfoOutlined,
  KeyboardArrowLeft,
  Payment,
} from "@mui/icons-material";
import OrderSummary from "./OrderSummary";
import Login from "./Login";
import SignUp from "./SignUp";
import { db } from "../config/firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import Toast from "./Toast";

// const stripePromise = loadStripe(
//   "pk_test_51IM7l2JOpsxdxVe2jBUVEIs2gYbh42Q47k4LFmBpeHlcZaKj6ZNDD46vjBQrTm7WPDIDtrnFF6AYRlPj0eVQna9t00T3RvP1Ui"
// );

const CheckoutModal = ({
  open,
  setOpen,
  event,
  ticketCounts,
  setTicketCounts,
  total,
  clientSecret,
  setClientSecret,
  showSuccess,
  setShowSuccess,
}) => {
  const { tickets } = event;
  const {
    userData,
    cart,
    defaultTheme,
    artistID,
    setError,
    setSuccess,
    setMessage,
    getTicketFee,
  } = useContext(AppContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [hasAddress, setHasAddress] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [exp, setExp] = useState("");
  const [cvv, setCvv] = useState("");
  const [zip, setZip] = useState("");
  const [userIsSet, setUserIsSet] = useState(false);
  const [loading, setLoading] = useState(false);

  const [displayInfo, setDisplayInfo] = useState(false);
  const [displayAuth, setDisplayAuth] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const stripePromise = event.test
    ? loadStripe(
        "pk_test_51IM7l2JOpsxdxVe2jBUVEIs2gYbh42Q47k4LFmBpeHlcZaKj6ZNDD46vjBQrTm7WPDIDtrnFF6AYRlPj0eVQna9t00T3RvP1Ui"
      )
    : loadStripe(
        "pk_live_51IM7l2JOpsxdxVe2sgvbNRrXl5wOl7atbigW1Ypva7qXB5JonKekA5nwGlWb5lZI9Mv17DifYkuOGbinq8HanViP00ZlDcHysm"
      );

  const subTotal = ticketCounts
    .map((amt, i) => amt * tickets[i].price)
    .reduce((val, acc) => {
      return val + acc;
    });

  const fees = ticketCounts
    .map((amt, i) => amt * getTicketFee(tickets[i].price))
    .reduce((val, acc) => {
      return val + acc;
    });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!userData?.guest && open) {
      createPaymentIntet();
    }
  }, [open]);

  const createPaymentIntet = async () => {
    setUserIsSet(true);

    const generateTicketString = (tickets, ticketCounts) => {
      return tickets
        .map(
          (ticket, index) =>
            `${ticketCounts[index]} ${ticket.name}${
              ticketCounts[index] > 1 ? "s" : ""
            }`
        )
        .join(", ");
    };

    setLoading(true);

    let metadata = {
      eventId: event.id,
      eventName: event.title,
      artistId: event.artistId,
      ticketCounts: JSON.stringify(ticketCounts),
      userId: userData?.uid,
      name:
        userData?.name.toLowerCase() !== "guest"
          ? `${userData.name}`
          : `${firstName} ${lastName}`,
      email: userData?.email ? userData.email : email,
      total: total.toFixed(2),
      sellerType: "artists",
    };

    console.log("THIS IS THE METADATA", metadata);

    const response = await axios
      .post(
        "https://us-central1-ticketshare-16a46.cloudfunctions.net/app/create-payment-intent",
        {
          total: Math.floor(total * 100),
          description: generateTicketString(event.tickets, ticketCounts),
          metadata: metadata,
        }
      )
      .catch((err) => {
        console.log(err);
      });
    if (response && response.status === 200) {
      setClientSecret(response.data.clientSecret);
      setLoading(false);
    }
  };

  const placeOrder = async () => {
    try {
      let purchaseDate = new Date();
      let allTickets = [];
      ticketCounts.forEach((count, idx) => {
        Array.from({ length: count }).map((_, i) => {
          const ticketId = uuidv4();
          let ticket = {
            eventId: event.id,
            ticketId: ticketId,
            ticketPrice: tickets[idx].price,
            ticketFee: getTicketFee(tickets[idx].price),
            ticketName: tickets[idx].name,
            eventName: event.title,
            eventStartTime: event.startDate,
            eventEndTime: event.endDate,
            eventLocation: event.location,
            eventFlyer: event.image,
            purchaserId: userData.uid,
            purchaserName: userData.name,
            purchaserEmail: userData.email,
            purchaseDate: purchaseDate,
            qrCode:
              window.location.origin +
              "/" +
              event.id +
              "/" +
              userData.uid +
              "/" +
              ticketId,
            artistId: artistID,
            checkedIn: false,
          };
          allTickets.push(ticket);
        });
      });

      //   console.log("Tickets =>", allTickets);

      const order = {
        eventId: event.id,
        eventName: event.title,
        eventDate: event.startDate,
        eventLocation: event.location,
        eventImage: event.image,
        eventDescription: event.description,
        tickets: allTickets,
        orderTotal: total,
        orderNumber: Math.floor(Math.random() * 999999999),
        purchaseDate,
        purchaserId: userData.uid,
        purchaserName: userData.name,
        purchaserEmail: userData.email,
      };

      //TODO: Charge card here and wait for success...
      // Create PaymentIntent as soon as the page loads

      const artistOrdersRef = db
        .collection("artists")
        .doc(artistID)
        .collection("orders");
      await artistOrdersRef.add(order);

      let eventCopy = { ...event };
      let ticketsCopy = eventCopy.tickets;
      let updatedTickets = ticketsCopy.map((t, idx) => {
        t.remaining -= ticketCounts[idx];
        t.sold += ticketCounts[idx];
        return t;
      });
      //   console.log("Updated tickets => ", updatedTickets);
      await db.collection("events").doc(event.id).update({
        tickets: updatedTickets,
      });

      allTickets.forEach((ticket) => {
        db.collection("users")
          .doc(userData.uid)
          .collection("tickets")
          .add(ticket);
      });

      setSuccess(true);
      setLoading(false);
      setMessage("Your purchase was processed successfully");
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
      setMessage("There was an error processing your purchase");
    }
  };

  useEffect(() => {}, [clientSecret]);

  //   useEffect(() => {
  //     //if hasAddress, scroll to bottom of element
  //     if (hasAddress) {
  //       const element = document.getElementById("checkout-modal");
  //       setTimeout(() => {
  //         element.scrollIntoView({ behavior: "smooth", block: "end" });
  //       }, 2000);
  //     }
  //   }, [hasAddress]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Toast />
        <Grid
          item
          container
          xs={12}
          md={8}
          lg={6}
          sx={{
            position: "absolute",
            bottom: { md: "50%", xs: "0%" },
            left: "50%",
            transform: {
              xs: "translate(-50%, 0%)",
              md: "translate(-50%, 50%)",
            },
            //   padding: "20px",
            background: "white",
            borderRadius: { xs: "12px 12px 0px 0px", md: "12px" },
            outline: "none",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={8}
            style={{ borderRight: "1px solid lightgrey" }}
          >
            <Grid
              item
              container
              xs={12}
              md={12}
              style={{
                textAlign: "center",
                borderBottom: "1px solid lightgrey",
                justifyContent: "space-between",
                height: "fit-content",
              }}
            >
              <IconButton>
                <KeyboardArrowLeft onClick={handleClose} />
              </IconButton>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Checkout
              </Typography>
              <IconButton>
                <Close onClick={handleClose} />
              </IconButton>
            </Grid>
            <Grid
              id="checkout-modal"
              item
              container
              xs={12}
              sx={{
                maxHeight: { xs: "80dvh", md: "70dvh" },
                overflowY: "scroll",
                padding: { xs: "20px", md: "20px 8%" },
              }}
            >
              <Typography
                variant="h5"
                style={{ width: "100%", fontWeight: "bold" }}
              >
                Billing Information
              </Typography>
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "space-between" }}
              >
                <Typography variant="caption">
                  {userData && !userData.guest ? (
                    `Logged in as, ${userData.name.split(" ")[0]}`
                  ) : (
                    <>
                      <span
                        onClick={() => setDisplayAuth(true)}
                        style={{
                          color: defaultTheme.palette.info.main,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Login
                      </span>{" "}
                      for a faster experience.
                    </>
                  )}
                </Typography>
                <Typography variant="caption">
                  {userData && !userData.guest ? "" : "* Required"}
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "space-between" }}
              >
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="First Name"
                    value={
                      userData && !userData.guest
                        ? userData.name.split(" ")[0]
                        : firstName
                    }
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="Last Name"
                    value={
                      userData && !userData.guest
                        ? userData.name.split(" ")[1]
                        : lastName
                    }
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="Email"
                    value={userData && !userData.guest ? userData.email : email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="Confirm Email"
                    value={
                      userData && !userData.guest
                        ? userData.email
                        : confirmEmail
                    }
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                  />
                </Grid>
                {userIsSet && (
                  <Typography
                    ariant="h5"
                    style={{ width: "100%", fontWeight: "bold" }}
                  >
                    Pay With
                  </Typography>
                )}

                {userIsSet ? (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      padding: 10,
                      border: "1px solid lightgrey",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {!!loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {clientSecret && (
                          <Elements options={options} stripe={stripePromise}>
                            <form style={{ width: "100%", marginBottom: 20 }}>
                              <AddressElement
                                options={{
                                  mode: "shipping",
                                  defaultValues: {
                                    name:
                                      userData.name &&
                                      userData.name.toLowerCase() !== "guest"
                                        ? userData.name
                                        : firstName + " " + lastName,
                                    autocomplete: {
                                      mode: "google_maps_api",
                                      apiKey:
                                        process.env.REACT_APP_GOOGLE_MAPS_API,
                                    },
                                  },
                                }}
                                onChange={(event) => {
                                  if (event.complete) {
                                    // Extract potentially complete address
                                    const address = event.value.address;
                                    setHasAddress(true);
                                  }
                                }}
                              />
                            </form>

                            {hasAddress && (
                              <CheckoutForm
                                firstName={firstName}
                                lastName={lastName}
                                email={email}
                                confirmEmail={confirmEmail}
                                subTotal={subTotal}
                                fees={fees}
                                setDisplayInfo={setDisplayInfo}
                                placeOrder={placeOrder}
                                event={event}
                                ticketCounts={ticketCounts}
                                clientSecret={clientSecret}
                                setClientSecret={setClientSecret}
                                loading={loading}
                                setLoading={setLoading}
                                showSuccess={showSuccess}
                                setShowSuccess={setShowSuccess}
                                open={open}
                                setOpen={setOpen}
                                setTicketCounts={setTicketCounts}
                              />
                            )}
                          </Elements>
                        )}
                      </>
                    )}
                  </Grid>
                ) : (
                  <Button
                    disabled={
                      !firstName ||
                      !lastName ||
                      !email ||
                      !confirmEmail ||
                      email !== confirmEmail ||
                      userIsSet
                    }
                    onClick={createPaymentIntet}
                    variant="contained"
                    color="primary"
                  >
                    {userIsSet ? (
                      <CircularProgress style={{ width: 24, height: 24 }} />
                    ) : (
                      "Next"
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={4}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                height: "28%",
                background: "lightgrey",
                borderRadius: "0px 12px 0px 0px",
                overflow: "hidden",
              }}
            >
              <img
                src={event.image}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <OrderSummary
              ticketCounts={ticketCounts}
              items={tickets}
              subTotal={subTotal}
              fees={fees}
            />
          </Grid>

          <Modal
            open={displayAuth}
            onClose={() => {
              setDisplayAuth(false);
            }}
          >
            <Grid
              imte
              container
              xs={11}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: defaultTheme.palette.white,
                padding: 20,
                borderRadius: 12,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Login" />
                <Tab label="Sign Up" />
              </Tabs>
              {activeTab === 0 && (
                <Login
                  isNotAuthPage={true}
                  close={() => setDisplayAuth(false)}
                />
              )}
              {activeTab === 1 && (
                <SignUp
                  isNotAuthPage={true}
                  close={() => setDisplayAuth(false)}
                />
              )}
            </Grid>
          </Modal>

          <Modal open={displayInfo} onClose={() => setDisplayInfo(false)}>
            <Grid
              item
              container
              xs={12}
              style={{
                position: "absolute",
                bottom: "0%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                background: "white",
                borderRadius: "12px 12px 0px 0px",
              }}
            >
              <OrderSummary
                ticketCounts={ticketCounts}
                items={tickets}
                subTotal={subTotal}
                fees={fees}
              />
            </Grid>
          </Modal>
        </Grid>
      </>
    </Modal>
  );
};

export default CheckoutModal;
