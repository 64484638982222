import React, { useContext, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { AppContext } from "../App";
import bookingLogo from "../assets/NewImage.webp";
import bookingText from "../assets/nie_text.webp";
import { Send } from "@mui/icons-material";
import { motion } from "framer-motion";
import { functions } from "../config/firebaseConfig";

const Contact = () => {
  const {
    defaultTheme,
    deviceType,
    contactRef,
    setSuccess,
    setInfo,
    setMessage,
    setError,
  } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setUserMessage] = useState("");
  const [budget, setBudget] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event, type) => {
    switch (type) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "subject":
        setSubject(event.target.value);
        break;
      case "message":
        setUserMessage(event.target.value);
        break;
      //   case "budget":
      //     setBudget(event.target.value);
      //     break;

      default:
        break;
    }
  };

  const handleClearForm = () => {
    setName("");
    setPhone("");
    setEmail("");
    setSubject("");
    setUserMessage("");
    // setBudget("");
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const contactMe = functions.httpsCallable("contactBookingAgent");
      const response = await contactMe({
        agent: "tonysr@iecesllc.com",
        name,
        email,
        phone,
        subject,
        message,
      });

      console.log("RESPONSE =>", response);

      if (response.data) {
        setSuccess(true);
        setMessage("Message sent successfully!");
        handleClearForm();
      } else {
        setError(true);
        setLoading(false);
        setMessage("Error sending message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "70dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
      }}
      ref={contactRef}
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          minHeight: { xs: "70dvh", md: "60dvh" },
          padding: { xs: "20px", md: "30px" },
          //   maxHeight: { md: "90dvh" },
        }}
        style={{
          textAlign: "center",
          background: defaultTheme.palette.black,
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{
            color: defaultTheme.palette.white,
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Booking Info
        </Typography>
        <Typography
          sx={{ marginBottom: { xs: "20px", md: "40px" } }}
          style={{ width: "100%", color: defaultTheme.palette.white }}
        >
          Fill out this form and contact us regarding any booking query you
          have, questions or requests and we’ll be happy to assist you!
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={4}
          style={{
            overflowY: deviceType === "mobile" ? "scroll" : "hidden",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              height: "fit-content",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: deviceType === "mobile" ? "50%" : "50%",
                objectFit: "contain",
              }}
              src={bookingLogo}
              alt="logo"
            />
            <img
              style={{
                width: deviceType === "mobile" ? "50%" : "50%",
                objectFit: "contain",
              }}
              src={bookingText}
              alt="logotext"
            />
            {/* <Typography style={{ width: "100%", color: "black" }}>
              New Image Entertainmentni
            </Typography> */}
            <Typography
              style={{
                width: "100%",
                color: "black",
                margin: "20px 0px",
                color: defaultTheme.palette.white,
              }}
            >
              Your #1 Booking Source for R&B and Old School Hip Hop
            </Typography>

            <Typography
              variant="caption"
              style={{
                marginBottom: 10,
                width: "100%",
                fontWeight: "bold",
                color: defaultTheme.palette.white,
              }}
            >
              NIE/TSE/IMPACT
              <br /> ENTERPRISES Booking
            </Typography>
            {/* <Typography
              variant="caption"
              style={{ marginBottom: 10, width: "100%" }}
            >
              Atlanta, GA
              <br />
              Cincinnati, OH
              <br />
              Dayton, OH
              <br />
              Birmingham, AL
              <br />
            </Typography> */}
            <Typography
              variant="caption"
              style={{
                marginBottom: 10,
                width: "100%",
                color: defaultTheme.palette.white,
              }}
            >
              E-mail: tonydsanders24@gmail.com
              <br />
              Office Tel: 678-242-9093
              <br />
              Mobile: 404-729-6501
              <br />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={8}
          style={{
            // maxHeight: "70dvh",
            overflowY: deviceType === "mobile" ? "scroll" : "hidden",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={8}
            style={{ justifyContent: "center" }}
          >
            <TextField
              fullWidth
              required
              id="name"
              label="Name"
              variant="outlined"
              value={name}
              style={{
                marginBottom: 10,
              }}
              inputProps={{
                style: {
                  background: defaultTheme.palette.white,
                  borderRadius: 4,
                },
              }}
              onChange={(e) => handleChange(e, "name")}
            />
            <TextField
              fullWidth
              required
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              style={{ marginBottom: 10 }}
              onChange={(e) => handleChange(e, "email")}
              inputProps={{
                style: {
                  background: defaultTheme.palette.white,
                  borderRadius: 4,
                },
              }}
            />
            <TextField
              required
              fullWidth
              id="phone"
              label="Phone"
              variant="outlined"
              value={phone}
              style={{ marginBottom: 10 }}
              onChange={(e) => handleChange(e, "phone")}
              inputProps={{
                style: {
                  background: defaultTheme.palette.white,
                  borderRadius: 4,
                },
              }}
            />

            <TextField
              fullWidth
              required
              id="subject"
              label="Subject"
              variant="outlined"
              value={subject}
              style={{ margin: "10px 0px" }}
              onChange={(e) => handleChange(e, "subject")}
              inputProps={{
                style: {
                  background: defaultTheme.palette.white,
                  borderRadius: 4,
                },
              }}
            />

            <TextField
              fullWidth
              required
              multiline
              minRows={4}
              maxRows={5}
              id="message"
              label="Message"
              variant="outlined"
              value={message}
              style={{ margin: "10px 0px" }}
              onChange={(e) => handleChange(e, "message")}
              InputProps={{
                style: {
                  background: defaultTheme.palette.white,
                  borderRadius: 4,
                },
              }}
            />

            <motion.button
              disabled={
                name === "" ||
                email === "" ||
                phone === "" ||
                subject === "" ||
                message === ""
              }
              onClick={handleSubmit}
              whileHover={{
                backgroundColor: defaultTheme.palette.secondary.main,
                color: defaultTheme.palette.black,
                scale: 0.98,
              }}
              style={{
                borderRadius: 40,
                height: 40,
                backgroundColor: defaultTheme.palette.primary.main,
                color: defaultTheme.palette.white,
                width: "100%",
                padding: "0px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{ width: 24, height: 24, color: "#00000050" }}
                />
              ) : (
                <>
                  {" "}
                  SEND <Send />
                </>
              )}
            </motion.button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
