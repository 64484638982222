import { Typography } from "@mui/material";

export const theme = {
  palette: {
    primary: {
      main: "#bf3cc9",
      dark: "#6d0e74",
      light: "#e277ea",
      //   main: "#42a5f5",
      //   dark: "#90caf9",
      //   light: "#e3f2fd",
    },
    secondary: {
      main: "#42a5f5",
      dark: "#90caf9",
      light: "#e3f2fd",
      //   main: "#ab47bc",
      //   dark: "#ce93d8",
      //   light: "#f3e5f5",
    },
    error: {
      main: "#f44336",
      dark: "#d32f2f",
      light: "#e57373",
    },
    warning: {
      main: "#ff9800",
      dark: "#f57c00",
      light: "#ffe082",
    },
    info: {
      main: "#2196f3",
      dark: "#1976d2",
      light: "#90caf9",
    },
    success: {
      main: "#4caf50",
      dark: "#2e7d32",
      light: "#c8e6c9",
    },
    black: "#000000",
    white: "#ffffff",
    grey: {
      main: "#808080",
      light: "#d3d3d3",
      dark: "#606060",
    },
  },
  typography: {
    fontFamily: [`"Montserrat", sans-serif`],
  },
};
