// src/components/Hero.js
import React, { useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";
import { Facebook, Instagram, X } from "@mui/icons-material";

const Hero = ({ homeRef }) => {
  const { defaultTheme, navigate, scrollToTop, artist } =
    useContext(AppContext);

  const navigateToSocialMedia = (account) => {
    if (account.length > 0) {
      window.open(`${account}`, "_blank");
    }
  };

  return (
    <Grid
      ref={homeRef}
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "90dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          containerType: "inline-size",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontWeight: "400",
            color: "white",
          }}
          sx={{
            fontSize: { xs: "15cqw", md: "8cqw" },
            marginTop: { xs: "-100px", md: "-0px" },
          }}
        >
          TIFFANY <strong>B</strong>
          <span
            style={{
              color: defaultTheme.palette.secondary.main,
            }}
          >
            .
          </span>
        </Typography>
        {/* <Typography
          variant="h1"
          style={{
            fontWeight: "800",
            color: defaultTheme.palette.black,
          }}
          sx={{ fontSize: { xs: "10cqw", md: "5cqw", marginTop: "-2%" } }}
        >
          BRYANT
        </Typography> */}
        {/* <Typography
          variant="h5"
          style={{
            color: "red",
            letterSpacing: "2px",
          }}
          sx={{ fontSize: { xs: "4cqw", md: "1cqw" } }}
        >
          THIS IS LEGENDARY R&B
        </Typography> */}
        <Button
          onClick={() => {
            navigate("/events");
            scrollToTop();
          }}
          variant="contained"
          color="primary"
          style={{
            color: defaultTheme.palette.white,
            borderRadius: 40,
            height: 40,
          }}
          sx={{
            fontSize: { xs: "3cqw", md: ".75cqw" },
            marginTop: { xs: "150px", md: "200px" },
          }}
        >
          Upcoming Shows
        </Button>
      </div>

      <Box
        sx={{
          position: "absolute",
          right: "2%",
          bottom: "2%",
          width: "fit-content",
          padding: "0px 5px",
          //   height: "40px",
          background: "white",
          borderRadius: "8px",
          zIndex: 4,
          textAlign: "right",
        }}
      >
        <Typography variant="caption">Social Media</Typography>
        <Grid item container xs={12}>
          <IconButton
            onClick={() => {
              navigateToSocialMedia(artist?.socialMediaLinks?.instagram);
            }}
            color="primary"
          >
            <Instagram />
          </IconButton>
          <IconButton
            onClick={() => {
              navigateToSocialMedia(artist?.socialMediaLinks?.instagram);
            }}
            color="secondary"
            style={{ marginLeft: 5 }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            onClick={() => {
              navigateToSocialMedia(artist?.socialMediaLinks?.instagram);
            }}
            style={{ marginLeft: 5 }}
          >
            <X />
          </IconButton>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Hero;
