// src/components/Profile.js
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputBase,
  Paper,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Modal,
} from "@mui/material";
import firebase, { db, auth, storage } from "../config/firebaseConfig";
import { AppContext } from "../App";
import { musicGenres } from "../utils/contants";
import PlacesAutoComplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    defaultTheme,
    navigate,
    scrollToTop,
    userData,
    setUserData,
    dispatch,
    setSuccess,
    setInfo,
    setError,
    setMessage,
  } = useContext(AppContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    genre: "",
    artistFullName: "",
    adminName: "",
    address: "",
    bio: "",
    preferredCommunication: "",
    payoutMethod: "",
    websiteUrl: "",
    socialMediaLinks: {
      facebook: "",
      instagram: "",
      twitter: "",
    },
    imageUrl: "",
    routingNumber: "",
    accountNumber: "",
  });

  const [showRouting, setShowRouting] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [restModalOpen, setResetModalOpen] = useState(false);

  const handleClickShowRouting = () => {
    setShowRouting(!showRouting);
  };
  const handleClickShowAccount = () => {
    setShowAccount(!showAccount);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (userData) {
      setFormData(userData);
    }
  }, [userData]);

  const handleChangeAddress = async (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);

    setFormData((prev) => ({ ...prev, address: value }));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      socialMediaLinks: {
        ...prevFormData.socialMediaLinks,
        [name]: value,
      },
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    try {
      if (file) {
        const userPath = userData.roles.includes("artist")
          ? `artists/profilePictures`
          : `users/profilePictures`;
        const storageRef = storage.ref();
        const fileRef = storageRef.child(`${userPath}/${userData.uid}`);
        await fileRef.put(file);
        const fileUrl = await fileRef.getDownloadURL();
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: fileUrl,
        }));

        const userType = userData.roles.includes("artist")
          ? "artists"
          : userData.roles.includes("promoter")
          ? "promoters"
          : "users";
        const userRef = db.collection(userType).doc(userData.uid);
        await userRef.update({ imageUrl: fileUrl });
        dispatch(setUserData({ ...userData, imageUrl: fileUrl }));
        setSuccess(true);
        setMessage("Profile Image updated successfully!");
      }
    } catch (error) {
      setError(true);
      setMessage("Failed to update profile image.");
    }
  };

  const handleSubmit = async () => {
    try {
      const userType = userData.roles.includes("artist")
        ? "artists"
        : userData.roles.includes("promoter")
        ? "promoters"
        : "users";
      const userRef = db.collection(userType).doc(userData.uid);
      await userRef.update(formData);
      dispatch(setUserData(formData));
      setSuccess(true);
      setMessage("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile: ", error);
      setError(true);
      setMessage("Failed to update profile.");
    }
  };

  const handleResetPassword = () => {
    auth
      .sendPasswordResetEmail(userData.adminEmail)
      .then(() => {
        setInfo(true);
        setMessage("Password reset email sent successfully.");
        setResetModalOpen(false);
      })
      .catch((error) => {
        setError(true);
        setMessage(
          "Can't reset at this time. You must log out and click 'Forgot Password'."
        );
      });
  };

  return (
    <>
      {userData && (
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            paddingTop: "120px",
            justifyContent: "center",
            maxWidth: "1200px",
            background: "white",
          }}
        >
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{
                borderBottom: "1px solid grey",
                padding: 10,
                marginBottom: 10,
              }}
            >
              <Avatar
                style={{ width: 60, height: 60, marginRight: 10 }}
                src={formData?.imageUrl}
              />
              <Box
                sx={{
                  width: "calc(100% - 70px)",
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: 0, fontWeight: "bold" }}
                >
                  {formData.name}
                </Typography>
                <Button
                  style={{ padding: "2px 5px" }}
                  onClick={() => fileInputRef.current.click()}
                >
                  Edit Display Image
                </Button>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} style={{ padding: 5 }}>
              <TextField
                fullWidth
                label={
                  userData.roles.includes("artist") ? "Artist Name" : "Name"
                }
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 5 }}>
              <TextField
                disabled
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 5 }}>
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Grid>
            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  label="Artist Full Name"
                  variant="outlined"
                  name="artistFullName"
                  value={formData.artistFullName}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Genre</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    label="genre"
                    name="genre"
                    value={formData.genre}
                    onChange={handleChange}
                    style={{ marginBottom: 20 }}
                  >
                    {musicGenres.map((genre) => (
                      <MenuItem key={genre} value={genre}>
                        {genre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  label="Admin Name"
                  variant="outlined"
                  name="adminName"
                  value={formData.adminName}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={6}
              style={{ padding: 5, position: "relative" }}
            >
              {/* <TextField
                fullWidth
                label="Address"
                variant="outlined"
                name="address"
                value={formData.address}
                onChange={handleChange}
              /> */}

              <Typography
                variant="caption"
                style={{
                  padding: 2,
                  background: "white",
                  position: "absolute",
                  top: "-7%",
                  left: "4%",
                  color: "grey",
                }}
              >
                Address
              </Typography>

              <PlacesAutoComplete
                value={formData.address}
                name="address"
                label="Address"
                onChange={handleChangeAddress}
                onSelect={handleSelect}
                style={{ width: "inherit" }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div style={{ width: "inherit" }}>
                    <Paper
                      elevation={0}
                      component="form"
                      style={{
                        // marginLeft: 5,
                        flex: 1,
                        display: "flex",
                        height: 50,
                        alignItems: "center",
                        width: "100%",
                        border: "1px solid grey",
                        borderRadius: 5,
                      }}
                    >
                      <InputBase
                        aria-controls="simple-menu"
                        onClick={handleClick}
                        {...getInputProps({
                          placeholder: "Enter Your Address...",
                        })}
                        style={{
                          marginLeft: 5,
                          flex: 1,
                          display: "flex",
                          height: 50,
                          alignItems: "center",
                          width: "100%",
                        }}
                      />

                      {/* <addressOnOutlined
						style={{
						color: defaultTheme.palette.primary.main,
						fontSize: "40px",
						cursor: "pointer",
						}}
					/> */}
                    </Paper>

                    <div
                      id="simple-menu"
                      anchorEl={anchorEl}
                      style={
                        formData.address !== ""
                          ? {
                              background: "white",
                              //   padding: 2,
                              //   border: "1px solid lightgrey",
                              //   boxShadow: "1px 1px 6px black",
                            }
                          : {
                              display: "none",
                            }
                      }
                    >
                      {loading && <MenuItem>Loading...</MenuItem>}
                      {suggestions.map((suggestion) => {
                        const style = suggestion.active
                          ? {
                              backgroundColor:
                                defaultTheme.palette.primary.light,
                              cursor: "pointer",
                            }
                          : { backgroundColor: "white" };

                        return (
                          <MenuItem
                            style={{ background: "white" }}
                            onClick={() => {}}
                            key={suggestion.description}
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                          >
                            {suggestion.description}
                          </MenuItem>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutoComplete>
            </Grid>

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  label="Website URL"
                  variant="outlined"
                  name="websiteUrl"
                  value={formData.websiteUrl}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="About"
                  variant="outlined"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <FormControl fullWidth>
                  <InputLabel id="pref-select-label">
                    Preferred Communication
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="pref-select-label"
                    label="Preferred Communication"
                    name="preferredCommunication"
                    value={formData.preferredCommunication}
                    onChange={handleChange}
                    style={{ marginBottom: 20 }}
                  >
                    {["Mail", "Email", "Phone"].map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <FormControl fullWidth>
                  <InputLabel id="pay-select-label">Payout Method</InputLabel>
                  <Select
                    fullWidth
                    labelId="pay-select-label"
                    label="Payout Method"
                    name="payoutMethod"
                    value={formData.payoutMethod}
                    onChange={handleChange}
                    style={{ marginBottom: 20 }}
                  >
                    {[
                      { name: "Check", value: "check" },
                      { name: "Bank Wire", value: "bankWire" },
                    ].map((type) => (
                      <MenuItem key={type.name} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {userData.roles.includes("artist") &&
              formData.payoutMethod === "bankWire" && (
                <Grid item xs={12} md={6} style={{ padding: 5 }}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-routing">
                      Routing Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-routing"
                      type={showRouting ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowRouting}
                            onMouseDown={handleMouseDown}
                            edge="end"
                          >
                            {showRouting ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Routing Number"
                      variant="outlined"
                      name="routingNumber"
                      fullWidth
                      value={formData.routingNumber}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              )}
            {userData.roles.includes("artist") &&
              formData.payoutMethod === "bankWire" && (
                <Grid item xs={12} md={6} style={{ padding: 5 }}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-account">
                      Account Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-account"
                      type={showAccount ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowAccount}
                            onMouseDown={handleMouseDown}
                            edge="end"
                          >
                            {showAccount ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                      label="Account Number"
                      variant="outlined"
                      name="accountNumber"
                      value={formData.accountNumber}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={4} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  label="Facebook"
                  variant="outlined"
                  name="facebook"
                  value={formData?.socialMediaLinks?.facebook}
                  onChange={handleSocialMediaChange}
                />
              </Grid>
            )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={4} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  label="Instagram"
                  variant="outlined"
                  name="instagram"
                  value={formData?.socialMediaLinks?.instagram}
                  onChange={handleSocialMediaChange}
                />
              </Grid>
            )}

            {userData.roles.includes("artist") && (
              <Grid item xs={12} md={4} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  label="Twitter"
                  variant="outlined"
                  name="twitter"
                  value={formData?.socialMediaLinks?.twitter}
                  onChange={handleSocialMediaChange}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4} style={{ padding: 5 }}>
              <Button variant="contained" fullWidth onClick={handleSubmit}>
                Update Profile
              </Button>
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: 5 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setResetModalOpen(true)}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={restModalOpen}
            onClose={() => {
              setResetModalOpen(false);
            }}
          >
            <Grid
              item
              container
              xs={11}
              md={5}
              lg={4}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "white",
                padding: 20,
                borderRadius: 12,
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Reset Password
              </Typography>
              <Typography
                style={{ width: "100%", textAlign: "center", marginBottom: 40 }}
              >
                Are you sure you would like to reset your password?
              </Typography>

              <Button
                variant="outlined"
                style={{ marginRight: 5 }}
                onClick={() => {
                  setResetModalOpen(false);
                }}
              >
                No, Cancel
              </Button>
              <Button variant="contained" onClick={handleResetPassword}>
                Yes, Confirm
              </Button>
            </Grid>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default Profile;
