// src/components/EventOverview.js
import React, { useContext } from "react";
import { Box, Grid, Tooltip, Typography, IconButton } from "@mui/material";
import {
  AttachMoneyOutlined,
  LocationOnOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import copy from "copy-to-clipboard";
import { AppContext } from "../App";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNames = [
  "January",
  "Februray",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EventOverview = ({ event }) => {
  const { userData, setInfo, setMessage } = useContext(AppContext);

  let date = event
    ? `${dayNames[new Date(event.startDate.seconds * 1000).getDay()]}, ${
        monthNames[new Date(event.startDate.seconds * 1000).getMonth()]
      } ${new Date(event.startDate.seconds * 1000).getDate()}`
    : "";

  let time = new Date(event.startDate.seconds * 1000)
    .toLocaleTimeString()
    .split(":00")
    .join(" ");

  let start = new Date(event.startDate.seconds * 1000).getTime();
  let end = new Date(event.endDate.seconds * 1000).getTime();

  let amotOfTimeInHours = Math.round((end - start) / 3600000);

  const url = window.location.href.split("/success")[0];

  return (
    <Grid item container xs={12} sx={{ marginBottom: "2rem", padding: "10px" }}>
      <Grid
        item
        container
        xs={12}
        style={{
          marginBottom: "20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {date} · {time}
        </Typography>
        <Box style={{ width: "fit-content" }}>
          <Grid
            item
            container
            xs={12}
            style={{ justifyContent: "flex-start", padding: "15px 0px" }}
          >
            <Typography
              variant="caption"
              style={{ width: "100%", textAlign: "left" }}
            >
              Share this event:
            </Typography>
            <FacebookShareButton
              url={url}
              quote={`Hey everyone! 🎉 I just bought my tickets to ${event?.title}, and I'm super excited! If you're free you should join me!`}
              hashtag={"#TicketShare"}
              style={{ marginRight: 5 }}
            >
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
              url={url}
              title={`Hey everyone! 🎉 I just bought my tickets to ${event?.title}, and I'm super excited! If you're free you should join me!`}
              hashtags={["TicketShare", "Events", "Fun"]}
              style={{ marginRight: 5 }}
            >
              <XIcon size={40} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton
              url={url}
              summary={`Hey everyone! 🎉 I just bought my tickets to ${event?.title}, and I'm super excited! If you're free you should join me!`}
              source={"TicketSHare"}
              style={{ marginRight: 5 }}
            >
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>

            <WhatsappShareButton
              url={url}
              title={`Hey everyone! 🎉 I just bought my tickets to ${event?.title}, and I'm super excited! If you're free you should join me!`}
              separator={" --> "}
              hashtag={"#TicketShare"}
              style={{ marginRight: 5 }}
            >
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>

            <CopyToClipboard
              text={`Hey everyone! 🎉 I just bought my tickets to ${event?.title}, and I'm super excited! If you're free you should join me --> ${url}`}
              onCopy={() => {
                setInfo(true);
                setMessage("Copied to clipboard");
              }}
            >
              <IconButton
                style={{ background: "lightgrey", width: 40, height: 40 }}
              >
                <ContentCopy />
              </IconButton>
            </CopyToClipboard>
          </Grid>
        </Box>
      </Grid>

      <Typography
        variant="h5"
        style={{ marginBottom: "20px", fontWeight: "bold" }}
      >
        {event.title}
      </Typography>

      <Grid
        item
        container
        xs={12}
        alignItems={"center"}
        style={{ marginBottom: 10 }}
      >
        <LocationOnOutlined style={{ marginRight: 5 }} />
        <Tooltip title="Click to copy">
          <Typography
            onClick={() => {
              copy(event.location);
              setInfo(true);
              setMessage("Address copied to clipboard.");
            }}
            variant="caption"
            style={{ fontWeight: "bold", cursor: "pointer" }}
          >
            {event.location}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems={"center"}
        style={{ marginBottom: 10 }}
      >
        <TimerOutlined style={{ marginRight: 5 }} />
        <Typography variant="caption" style={{ fontWeight: "bold" }}>
          {amotOfTimeInHours} hours
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems={""}
        style={{
          marginBottom: 20,
          paddingBottom: 30,
          borderBottom: "1px solid lightgrey",
        }}
      >
        <AttachMoneyOutlined style={{ marginRight: 5 }} />
        <Typography
          variant="caption"
          style={{ fontWeight: "bold", width: "90%" }}
        >
          Refund Policy <br />
          <span style={{ color: "grey" }}>
            The organizer will review refund request on a case by case basis.
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems={""}
        style={{
          marginBottom: 20,
          paddingBottom: 30,
          borderBottom: "1px solid lightgrey",
        }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold", width: "100%" }}>
          About this event
        </Typography>
        <Typography style={{ margin: "20px 0px", whiteSpace: "pre-line" }}>
          {event.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EventOverview;
